<template>
  <div v-if="getNotes.length">
    <div class="flix-form-group flix-text-center" v-if="!all && getNotes.length > 1">
      <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="changeNote(1)">&laquo;</a>
      {{ Object.keys(notes).length - 1 - show }} / {{ Object.keys(notes).length -1 }}
      <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="changeNote(-1)">&raquo;</a>
    </div>
    <transition name="animation">
      <div :key="show">
        <div class="flix-note flix-bg-warning" v-show="index == show || all" v-for="(note, index) in getNotes" :key="index">
          <div class="flix-html" v-html="note.msg" />
          <hr class="flix-html-hr" />
          <div class="flix-html-small flix-user"><flixIcon :id="'user'" /> {{ note.user }}</div>
          <div class="flix-html-small flix-date"><flixIcon :id="'calendar'" /> {{ $getNullsBefore(note.date.getDate()) }}.{{ $getNullsBefore(note.date.getMonth() + 1) }}.{{ note.date.getFullYear() }} <flixIcon :id="'clock'" /> {{ $getNullsBefore(note.date.getHours()) }}:{{ $getNullsBefore(note.date.getMinutes()) }}</div>
        </div>
      </div>
    </transition>
    <div class="flix-form-group flix-text-right" v-if="getNotes.length > 1">
      <a href="#" class="flix-btn flix-btn-default flix-btn-xs" v-if="!all" @click.prevent="all = !all"><flixIcon :id="'expand'" /> {{ getNotes.length }}</a>
      <a href="#" class="flix-btn flix-btn-default flix-btn-xs" v-if="all" @click.prevent="all = !all"><flixIcon :id="'collapse-top'" /> 1</a>
    </div>
  </div>
</template>
<script>
export default {
  props: { notes: Object },
  data () {
    return {
      open: false,
      show: 0,
      all: false
    }
  },
  methods: {
    changeNote (add) {
      this.show = this.show + add
      var all = this.getNotes.length

      if (this.show < 0) {
        this.show = all - 1
        return false
      }

      if (this.show === (all * 1)) {
        this.show = 0
      }
    }
  },
  computed: {
    getNotes () {
      var notes = JSON.parse(JSON.stringify(this.notes))
      var r = []
      var keys = Object.keys(notes)
      keys.forEach(function (k) {
        if (k * 1 === 0) {
          return true
        }
        notes[k].date = this.$createDate(notes[k].date)
        r.push(notes[k])
      }.bind(this))

      r.reverse()
      return r
    }
  },
  mounted () {
  }
}
</script>
<style scoped lang="sass">
.flix-note
  transition: all .2s ease-in
  box-shadow: 0px 24px 12px -18px rgba(0,0,0,0.5)
  color: black
  display: block
  min-height: 20px
  padding: 19px
  margin-bottom: 20px
  border-radius: 5px

.flix-note:nth-child(2n)
  transform: rotate(2deg)

.flix-note:hover
  transform: rotate(0deg)
  // box-shadow: inherit

.flix-html-hr
  margin: 8px 0
  border-color: black
  border-style: dotted
.flix-date
  font-size: 9pt
  display: block
  text-align: right
.flix-user
  font-size: 9pt
  float: left

.animation-enter-active
  animation: openMe 0.4s ease-in-out
  transform-origin: center center

@keyframes openMe
  from
    transform: scale3d(-1.4, 0.6, 0.6)
    z-index: 1
  to
    transform: scale3d(1,1,1)
    z-index: 0
</style>
